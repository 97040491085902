import screenfull from 'screenfull'

const message = useMessage()
export function onFullScreen(domId: string) {
  if (!screenfull.isEnabled) {
    message.warning('暂不不支持全屏')
    return false
  }

  const element = document.querySelector(domId)
  if (!element)
    return

  if (screenfull.isEnabled && !screenfull.isFullscreen) {
    screenfull.request(element, { navigationUI: 'hide' })
  }
  else {
    screenfull.toggle()
  }
}
